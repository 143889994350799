<template>
  <div class="user">
    <van-nav-bar title="" left-text="返回" right-text="按钮">
      <van-icon name="volume-o" slot="left" />
      <van-icon name="setting-o" slot="right" />
    </van-nav-bar>

    <van-grid column-num="3" :border="false">
      <van-grid-item
        icon="photo-o"
        :text="$t(item.t)"
        v-for="item in gridItemArr"
        :key="item.id"
        @click="item.callBack()"
      />
    </van-grid>
    <div class="logout">
      <van-button color="#2f3848" block type="default">{{
        $t("My.logout")
      }}</van-button>
    </div>
    <van-action-sheet
      v-model="show"
      :actions="actions"
      @close="onClose"
      @select="onSelect"
    />
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
} from "vant";


// 设置vant的多语言
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";

export default {
  data() {
    return {
      test: {
        personal: "Personal information",
        preOrde: "Pre-order record",
        account: "Account details",
        recharge: "Recharge record",
        withdrawal: "Withdrawal record",
        invite: "Invite friends",
        announcement: "Announcement",
        team: "Team report",
        language: "Language setting",
      },
      gridItemArr: [
        { id: 1, t: `My.personal`, callBack: () => {} },
        { id: 2, t: `My.preOrde`, callBack: () => {} },
        { id: 3, t: `My.account`, callBack: () => {} },
        { id: 4, t: `My.recharge`, callBack: () => {} },
        { id: 5, t: `My.withdrawal`, callBack: () => {} },
        { id: 6, t: `My.invite`, callBack: () => {} },
        { id: 7, t: `My.announcement`, callBack: () => {} },
        { id: 8, t: `My.team`, callBack: () => {} },
        {
          id: 9,
          t: `My.language`,
          callBack: () => {
            this.show = true;
          },
        },
      ],
      show: false,
      actions: [
        {
          name: this.$t("My.languageButton.english"),
          type: "en",
        },
        {
          name: this.$t("My.languageButton.indonesian"),
          type: "idn",
        },
        {
          disabled: true,
        },
      ],
    };
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button
  },
  methods: {
    onSelect(e) {
      switch (e.type) {
        case "en":
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
        case "idn":
          window.console.log(e.type);
          this.$i18n.locale = e.type;
          Locale.use("en-US", enUS);
          Cookies.set("language", e.type);
          break;
      }
      this.show = false;
    },
    onClose() {
      this.show = false;
    },
  },
};
</script>

<style lang="less">
.user {
  .logout {
    margin: 10px;
  }
}
</style>
